import React, { useEffect, useRef } from "react";
import { Context } from "../../appcontext";
import { getAttributes, getDecimalsCountByFormat, setCustomAttributes } from "../../common/common";
import { TNclInputType, TInputMultiLineMode, cJSonFunctionExecuteShortcut } from "../../common/communication.base";
import { EditCheckValueResult, NclLocatorPanel, NclQuickFilter } from "../../common/components.ncl";
import { InnerInputProps, InputHtmlAttributes, InputProps, createHint } from "./utils";
import css from "./Input.scss";

interface InnerSimpleInputProps extends InnerInputProps, InputProps {
  showContextMenu?: () => void;
}

export default function K2InnerSimpleInput(props: InnerSimpleInputProps) {
  const element = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const contextMenuTimer = useRef(0);
  const possibleShowContextMenu = useRef(false);

  useEffect(() => {
    if (props.control.isDateOrTime() && props.control.ToolBar) {
      props.control.ToolBar.removeAction("_ExecuteLookupCOMMAND");
    }

    if (props.blockSoftKeyboard) {
      focus();
    }
  }, []);

  useEffect(() => {
    if (props.data.PasteText && props.data.PasteText.length > 0) {
      //Po změně dat by se jinak přesunul kurzor na konec inputu
      if (props.lastSelectedPosition?.current != undefined) {
        element.current?.setSelectionRange(props.lastSelectedPosition.current.start, props.lastSelectedPosition.current.end);
      }
      //SimpleInput neposílá data po Blur, tudíž zde po PasteTextu
      props.change(value);
      props.control.accept();
    }
  }, [props.value]);

  useEffect(() => {
    if (props.focus) {
      focus();
    }
  }, [props.focus]);

  /**
   * Zakazuje/povoluje zobrazeni SW klavesnice pri renderu focusnuteho inputu.
   *
   * Na iOS se klavesnice skryje, ale input neni aktivni. Delo se tak uz i pred prepisem inputu.
   * Mozna je to vlastnost WebKitu.
   */
  function focus() {
    if (!element.current) return;

    if (Context.DeviceInfo.ShowKeyboardOnFocus === "1") {
      element.current.focus();
    } else {
      element.current.inputMode = "none";
      element.current.readOnly = true;
      element.current.focus();
      element.current.readOnly = false;
      element.current.inputMode = getInputMode();
    }
  }

  function getInputType(): string {
    if (isSearchControl()) return "search";

    switch (props.control.State.InputType) {
      case TNclInputType.nitDateTime:
        return "datetime-local"; // datetime je deprecated
      case TNclInputType.nitDate:
        return "date";
      case TNclInputType.nitTime:
        return "time";
      case TNclInputType.nitNumeric:
        return "number";
      default:
        break;
    }

    return "text";
  }

  function getInputMode(): "numeric" | "decimal" | "text" {
    if (props.control.State.InputType === TNclInputType.nitNumeric) {
      if (getDecimalsCountByFormat(props.control.State.Format) > 0) {
        return "decimal";
      } else {
        return "numeric";
      }
    }

    return null; // Pokud se vrati typ inputmode = 'text', tak Enter na SW klavesnici se chova jako novy radek a ne jako potvrzeni, a neodesle se tak pozadavek na server; napr. odeslani hodnoty u sloupcoveho filtru.
  }

  function getStep(): string {
    if (props.control.State.InputType == TNclInputType.nitNumeric) {
      return (1 / Math.pow(10, 4)).toString();
    }

    return "1";
  }

  function refCallBack(el: HTMLInputElement | HTMLTextAreaElement | null) {
    if (!el) return;

    setCustomAttributes(el, props.control.Ncl.Attributes);
    element.current = el;

    if ((Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS) && Context.DeviceInfo.IsTouchDevice && element.current instanceof HTMLInputElement) {
      element.current.defaultValue = "";
    }

    props.initRef(element.current);
  }

  function isSearchControl(): boolean {
    return props.control.Parent instanceof NclQuickFilter || props.control.Parent instanceof NclLocatorPanel;
  }

  function isTextArea(): boolean {
    if (props.control.State.InputType === TNclInputType.nitString && props.data.MultiLineMode) return true;
    return false;
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y" ||
      e.key === "-" ||
      e.code === "NumpadDecimal" ||
      e.key.match(/^[0-9]$/) ||
      e.key === "Backspace"
    ) {
      e.stopPropagation();
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.change) {
      const value = e.target.value;
      const accept = props.control.checkInput(props.value, value, { start: 0, end: 0 }, true);

      switch (props.control.State.InputType) {
        case TNclInputType.nitNumeric:
          if (!value || (accept && accept.Result === EditCheckValueResult.newValue)) {
            if (props.value != value) {
              props.change(value);
              props.setValue(value);
            }
          }

          break;
        case TNclInputType.nitDateTime:
        case TNclInputType.nitDate:
        case TNclInputType.nitTime:
          if (props.value != value && (value !== "" || (value === "" && !props.control.isEmpty()))) {
            props.change(value);
            props.control.accept();
          }
          break;
        case TNclInputType.nitString:
          if (props.value != value) {
            props.change(value);
            props.setValue(value);

            // Pokud je input typu MultiLineMode, vykresluje se jako <textarea>.
            // Na mobilech nelze poznat klavesu Enter, takze akci vyvolavam, pokud hodnota konci zalomenim radku.
            if (!isSingleLine() && endsWithEnter(value)) {
              props.control.appendFunction({ Name: cJSonFunctionExecuteShortcut, Args: ["ENTER"] }, true);
            }
          }
          break;
      }
    }
  }

  function isSingleLine() {
    if (props.data.MultiLineMode === TInputMultiLineMode.imlmSingleLine) {
      return true;
    }

    return false;
  }

  function endsWithEnter(value: string) {
    if (value.match(/\n$/)) {
      return true;
    }

    return false;
  }

  function handleTouchStart() {
    // pred zobrazenim datepickeru se nastavi aktualni datum (melo by stacit nastavit prazdny string),
    // aby uzivatel nemusel scrollovat od roku 1
    if (props.invalidDate) {
      props.setValue("");
    }

    possibleShowContextMenu.current = true;
    contextMenuTimer.current = window.setTimeout(() => {
      if (possibleShowContextMenu.current) {
        possibleShowContextMenu.current = false;
        if (props.showContextMenu) {
          props.showContextMenu();
        }
      }
    }, 610);
  }

  function handleTouchEnd(e: React.TouchEvent) {
    props.lastSelectedPosition.current = { start: element.current.selectionStart, end: element.current.selectionEnd }; // Pro případ že se bude provádět akce mimo input pracující s PasteText (např. klik na button

    if (possibleShowContextMenu.current === false) {
      e.preventDefault();
    }

    possibleShowContextMenu.current = false;
    clearTimeout(contextMenuTimer.current);
  }

  function handleTouchMove() {
    possibleShowContextMenu.current = false;
    clearTimeout(contextMenuTimer.current);
  }

  function handleContextMenu(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation(); //on android show context menu with copy/paste/...
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.onFocus) {
      props.onFocus(e);
    }

    if (!(props.control.Parent instanceof NclLocatorPanel) && !props.blockSoftKeyboard) {
      if (props.lastSelectedPosition?.current != undefined && isTextArea()) {
        element.current?.setSelectionRange(props.lastSelectedPosition.current.start, props.lastSelectedPosition.current.end);
      } else {
        // Input se při focusu vybírá celý
        element.current?.select();
      }
    }
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.lastSelectedPosition.current = { start: element.current.selectionStart, end: element.current.selectionEnd }; // Pro případ že se bude provádět akce mimo input pracující s PasteText (např. klik na button
    if (props.onBlur) {
      props.onBlur(e);
    }
    props.control.accept();
  }

  // iOS/iPadOS bug - https://github.com/facebook/react/issues/8938
  if (
    (Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS) &&
    Context.DeviceInfo.IsTouchDevice &&
    element.current instanceof HTMLInputElement &&
    props.control.isDateOrTime()
  ) {
    const target = element.current;
    setTimeout(() => {
      target.defaultValue = "";
    }, 100);
  }
  const value = props.value;
  const inputClass: string = css.in_input + " " + props.className + (props.invalidDate ? " in_invalid_value" : "");
  const inputAttributes: InputHtmlAttributes = {
    type: getInputType(),
    id: props.control.Ncl.Name,
    title: createHint(props.data.Hint, value),
    ...getAttributes(props.data),
  };

  if (isSearchControl()) {
    inputAttributes.tabIndex = -1;
  }

  inputAttributes.autoComplete = "off";
  inputAttributes.placeholder = props.data.Watermark;
  if (props.data.MaxLength) {
    inputAttributes.maxLength = props.data.MaxLength;
  }

  if (props.control.Ncl.FrgtData.IsPassword) {
    inputAttributes.type = props.revealPassword ? "text" : "password";
    inputAttributes.title = undefined;
  }
  if (isTextArea()) {
    return (
      <textarea
        className={inputClass}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onContextMenu={handleContextMenu}
        ref={refCallBack}
        {...inputAttributes}
        value={value}
        rows={props.data.MultiLineMode == TInputMultiLineMode.imlmMultiLineSimple ? props.control.Ncl.FrgtData.Size : 0}
        onKeyDown={handleKeyDown}
      />
    );
  } else {
    return (
      <>
        <input
          className={inputClass}
          ref={refCallBack}
          inputMode={getInputMode()}
          step={getStep()}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onPaste={props.onPaste}
          onTouchEnd={handleTouchEnd}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          //onTouchCancel={handleTouchCancel}
          onContextMenu={handleContextMenu}
          onKeyDown={handleKeyDown}
          {...inputAttributes}
        />
      </>
    );
  }
}
