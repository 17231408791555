import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  ConnectionMode,
  Edge,
  MiniMap,
  Node,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
} from "reactflow";
import { UpdateFlowChart, StepType, EdgeUpdateType } from "../../common/communication.base";
import { NclFlowChart } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import EdgeFloating from "./EdgeFloating";
import EdgeStraight from "./EdgeStraight";
import NodeCaption from "./NodeCaption";
import NodeEdge from "./NodeEdge";
import NodeStep from "./NodeStep";
import Menubar from "./Menubar";
import "reactflow/dist/style.css";
import "./FlowChart.scss";
import { SvgStepStart, SvgStepEnd, SvgStepTransfer, SvgStepAcknowledge, SvgStepCase, SvgStepDistrib, SvgStepSubModel } from "./SvgStep";

const nodeTypes = {
  Step: NodeStep,
  Caption: NodeCaption,
  Edge: NodeEdge,
};

const edgeTypes = {
  Floating: EdgeFloating,
  straight: EdgeStraight,
};

function K2FlowChartWithProvider(props: WithContextPlacementProps) {
  return (
    <ReactFlowProvider>
      <K2FlowChart controlUID={props.controlUID} vrUID={props.vrUID} style={props.style} />
    </ReactFlowProvider>
  );
}

function K2FlowChart(props: WithContextPlacementProps) {
  const [control, data, element] = useServerState<NclFlowChart, UpdateFlowChart, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFlowChart
  );
  const zoomLevel = useStore((store) => store.transform[2]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [menuActions, setMenuActions] = useState(actions);
  const ctrl = useRef(false);
  const clickTime = useRef(-1);
  const clickDetected = useRef(false);
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    if (data.nodes == null || data.edges == null) return;

    setNodes(data.nodes.toJS() as Node[]);
    setEdges(data.edges.toJS() as Edge[]);

    if (!data.nodesConnectable) {
      setMenuActions(actions);
    }
  }, [data]);

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    console.log("onEdgeUpdate");
    if (oldEdge.source !== newConnection.source && newConnection.source) {
      control.edgeUpdate(oldEdge.id, String(EdgeUpdateType.rejoinSource), newConnection.source);
    }
    if (oldEdge.target !== newConnection.target && newConnection.target) {
      control.edgeUpdate(oldEdge.id, String(EdgeUpdateType.rejoinTarget), newConnection.target);
    }
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  };

  const onEdgeUpdateStart = () => {
    if (data.nodes == null) return;
    const nodes = data.nodes.toJS().map((node: Node) => nodeChangeConnectable(node, true));
    setNodes(nodes);
  };

  const onEdgeUpdateEnd = () => {
    if (data.nodes == null) return;
    const nodes = data.nodes.toJS().map((node: Node) => nodeChangeConnectable(node, false));
    setNodes(nodes);
  };

  const nodeChangeConnectable = (node: Node, state: boolean) => {
    if (node.type === "Step") node.data.isConnectable = state;
    return node;
  };

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      if (params.source == null || params.target == null) return;

      const selectedAction = menuActions.find((action) => action.checked === true);
      if (!selectedAction || ["action0"].includes(selectedAction.id)) return;

      let edgeType: string;
      switch (selectedAction.id) {
        case "action8":
          edgeType = "approve";
          break;
        case "action9":
          edgeType = "reject";
          break;
        default:
          return;
      }

      console.log("AddLink", params.source, params.target, edgeType);
      control.addLink(params.source, params.target, edgeType);
    },
    [menuActions]
  );

  function updateActions(actions: MenuActions[]) {
    setMenuActions(actions);

    const selectedAction = actions.find((action) => action.checked === true);

    switch (selectedAction?.id) {
      case "action0":
        console.log("default");
        control.toolBarClick("default");
        break;
      case "action8":
      case "action9":
        console.log("edge");
        control.toolBarClick("edge");
        break;
      default:
        console.log("node");
        control.toolBarClick("node");
        break;
    }
  }

  function updateCtrlKey(ctrlKey: boolean) {
    if (ctrlKey) {
      ctrl.current = true;

      return;
    }

    ctrl.current = false;
  }

  function handleNodeClick(e: React.MouseEvent, node: Node) {
    console.log("NodeClick", node.id);
    control.nodeClick(node.id);
  }

  function handleNodeDoubleClick(e: React.MouseEvent, node: Node) {
    console.log("NodeDoubleClick", node.id);
    control.nodeDoubleClick(node.id);
  }

  function handleEdgeClick(e: React.MouseEvent, edge: Edge) {
    let dlbclick: boolean;
    const now: number = Date.now();
    const diff: number = now - clickTime.current;

    if (clickDetected.current && diff < 400) {
      dlbclick = true;
      clickDetected.current = false;
      clickTime.current = 0;
    } else {
      dlbclick = false;
      clickDetected.current = true;
      clickTime.current = Date.now();
    }

    if (!dlbclick) {
      console.log("EdgeClick", edge.id);
      control.edgeClick(edge.id);
    } else {
      console.log("EdgeDoubleClick", edge.id);
      control.edgeDoubleClick(edge.id);
    }
  }

  function handleNodeDragStop(e: React.MouseEvent, node: Node, nodes: Node[]) {
    console.log("NodeDragStop", node.id, node.position.x.toString(), node.position.y.toString());
    control.nodeDragStop(node.id, node.position.x.toString(), node.position.y.toString());
  }

  function handleClick(e: React.MouseEvent) {
    const selectedAction = menuActions.find((action) => action.checked === true);

    if (!selectedAction || ["action0", "action8", "action9"].includes(selectedAction.id)) return;

    if (!ctrl.current) {
      const newActions = menuActions.map((action) => {
        return {
          ...action,
          checked: action.id === "action0" ? true : false,
        };
      });

      setMenuActions(newActions);
    }

    const reactFlowBounds = element.current?.getBoundingClientRect();

    if (!reactFlowBounds) return;

    const x = (e.clientX - reactFlowInstance.getViewport().x - reactFlowBounds.left) / reactFlowInstance.getViewport().zoom;
    const y = (e.clientY - reactFlowInstance.getViewport().y - reactFlowBounds.top) / reactFlowInstance.getViewport().zoom;

    control.addNode(selectedAction.stepType.toString(), x.toString(), y.toString());
  }

  return (
    <div className={"flc " + (data.nodesConnectable ? "inEdit" : "")} style={StyleHelper(control, props.style)}>
      <Menubar actions={menuActions} updateActions={updateActions} updateCtrlKey={updateCtrlKey} zoomLevel={zoomLevel} />
      <div ref={element} className="flc_main">
        <div style={{ width: 0, height: 0 }}>
          <svg>
            <defs>
              <marker
                className="react-flow__arrowhead"
                id="arrowActive"
                markerWidth="12.5"
                markerHeight="12.5"
                viewBox="-10 -10 20 20"
                markerUnits="strokeWidth"
                orient="auto"
                refX="0"
                refY="0"
              >
                <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" points="-5,-4 0,0 -5,4 -5,-4"></polyline>
              </marker>
              <marker
                className="react-flow__arrowhead"
                id="arrowBack"
                markerWidth="12.5"
                markerHeight="12.5"
                viewBox="-10 -10 20 20"
                markerUnits="strokeWidth"
                orient="auto"
                refX="0"
                refY="0"
              >
                <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" points="-5,-4 0,0 -5,4 -5,-4"></polyline>
              </marker>
            </defs>
          </svg>
        </div>
        <ReactFlow
          onClick={handleClick}
          //          onDrop={onDrop}
          //onDragOver={onDragOver}
          onNodeClick={handleNodeClick}
          onNodeDoubleClick={handleNodeDoubleClick}
          onEdgeClick={handleEdgeClick}
          onNodeDragStop={handleNodeDragStop}
          // onConnect={handleConnection}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          elevateEdgesOnSelect={true}
          onConnect={onConnect}
          panOnDrag={data.panOnDrag}
          // zoomOnScroll={false}
          zoomOnDoubleClick={false}
          nodesDraggable={data.nodesConnectable && !data.Options?.LockControls}
          nodesConnectable={data.nodesConnectable}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          connectionMode={ConnectionMode.Loose} //allows connecting Source to Source handle
          connectionLineType={ConnectionLineType.Straight}
          snapGrid={[data.Options?.GridGapSize, data.Options?.GridGapSize]}
          snapToGrid={data.Options?.GridEnabled}
        >
          <MiniMap />
          {data.Options?.GridEnabled && data.Options?.ShowGrid && (
            <Background gap={data.Options?.GridGapSize} offset={2} color="#00000010" variant={BackgroundVariant.Lines} />
          )}
        </ReactFlow>
      </div>
    </div>
  );
}

export default K2FlowChartWithProvider;

export interface MenuActions {
  id: string;
  stepType: StepType;
  name: string;
  title: string;
  image: JSX.Element;
  checked: boolean;
}

const actions: MenuActions[] = [
  {
    id: "action0",
    stepType: StepType.itUndefined,
    name: "node",
    title: "Výběr",
    image: (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        fillRule="evenodd"
        clipRule={"evenodd"}
        strokeLinejoin={"round"}
        strokeMiterlimit={"2"}
      >
        <path
          d="M16,2.586L11.293,7.293L12.707,8.707L15,6.414L15,13L17,13L17,6.414L19.293,8.707L20.707,7.293L16,2.586ZM7.293,11.293L2.586,16L7.293,20.707L8.707,19.293L6.414,17L13,17L13,15L6.414,15L8.707,12.707L7.293,11.293ZM24.707,11.293L23.293,12.707L25.586,15L19,15L19,17L25.586,17L23.293,19.293L24.707,20.707L29.414,16L24.707,11.293ZM15,19L15,25.586L12.707,23.293L11.293,24.707L16,29.414L20.707,24.707L19.293,23.293L17,25.586L17,19L15,19Z"
          fillRule="nonzero"
        />
      </svg>
    ),
    checked: true,
  },
  {
    id: "action1",
    stepType: StepType.itStart,
    name: "node",
    title: "Zahájení",
    image: <SvgStepStart />,
    checked: false,
  },
  {
    id: "action2",
    stepType: StepType.itEnd,
    name: "node",
    title: "Ukončení",
    image: <SvgStepEnd />,
    checked: false,
  },
  {
    id: "action3",
    stepType: StepType.itTransfer,
    name: "node",
    title: "Transformace",
    image: <SvgStepTransfer />,
    checked: false,
  },
  {
    id: "action4",
    stepType: StepType.itAcknowledge,
    name: "node",
    title: "Na vědomí",
    image: <SvgStepAcknowledge />,
    checked: false,
  },
  {
    id: "action5",
    stepType: StepType.itCase,
    name: "node",
    title: "Rozhodnutí",
    image: <SvgStepCase />,
    checked: false,
  },
  {
    id: "action6",
    stepType: StepType.itDistrib,
    name: "node",
    title: "Distribuce",
    image: <SvgStepDistrib />,
    checked: false,
  },
  {
    id: "action7",
    stepType: StepType.itSubModel,
    name: "node",
    title: "Vnořený postup",
    image: <SvgStepSubModel />,
    checked: false,
  },
  {
    id: "action8",
    stepType: StepType.itUndefined,
    name: "edge",
    title: "Spoj",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
      </svg>
    ),
    checked: false,
  },
  {
    id: "action9",
    stepType: StepType.itUndefined,
    name: "edge",
    title: "Vedlejší spoj",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
      </svg>
    ),
    checked: false,
  },
];
