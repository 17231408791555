import React, { useCallback, useEffect, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";
import { UpdateMap } from "../../common/communication.base";
import { NclMap } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import css from "./Map.scss";
import "./SMap";

const defaultZoom = 10;

const K2Map = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclMap, UpdateMap, HTMLDivElement>(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclMap);
  const nomarker = useRef<HTMLDivElement>();
  const maplink = useRef<HTMLAnchorElement>();
  const rendered = useRef(false);
  const mapa = useRef<Window["SMap"]>(null);
  const mapa_layer = useRef<any>(null);
  const refresh_timer = useRef<NodeJS.Timeout>();
  const resize_timer = useRef<NodeJS.Timeout>();
  // const overlay_timer = useRef(null);
  // const mobile_message = useRef<HTMLDivElement>();
  let gps_data: string[];

  const createMap = () => {
    // Vytvoření mapy
    mapa.current = new window.SMap(element.current, null, defaultZoom);

    // Přidání vrstvy se značkama
    mapa_layer.current = new window.SMap.Layer.Marker();
    mapa.current.addLayer(mapa_layer.current);
    mapa_layer.current.enable();

    updateData();
  };

  // const showMobileMessage = () => {
  //   mobile_message.current.classList.add("active");
  //   if (overlay_timer.current !== null) clearTimeout(overlay_timer.current);
  //   overlay_timer.current = setTimeout(() => {
  //     hideMobileMessage();
  //   }, 2000);
  // };
  // const hideMobileMessage = () => {
  //   mobile_message.current.classList.remove("active");
  // };

  // Vyhledání pozice markeru
  const updateData = () => {
    if (data.GPS != "") {
      gps_data = data.GPS.split(",");
      if (maplink.current) maplink.current.setAttribute("href", "http://www.mapy.cz/?query=" + gps_data[0] + ", " + gps_data[1]);
      updateLayer();
    } else {
      let address = data.Street;
      address += " " + data.DescrNo + (data.OrientNo != "" && data.DescrNo) ? "/" : "" + data.OrientNo; //Ternár kvůli lomena
      if (data.City != "") address += ", " + data.City; //Podmínka kvůli čárky
      if (data.Country != "") address += ", " + data.Country; //Podmínka kvůli čárky

      if (maplink.current) maplink.current.setAttribute("href", "http://www.mapy.cz/?query=" + address);
      new window.SMap.Geocoder(address, geolocationQuery);
    }
  };

  const geolocationQuery = (geocoder: any) => {
    if (!geocoder.getResults()[0].results.length) {
      nomarker.current?.classList.add("active");
      return;
    }

    // Uloží výsledek
    const vysledky = geocoder.getResults()[0].results;
    gps_data = [vysledky[0].coords.y.toString(), vysledky[0].coords.x.toString()];
    updateLayer();
  };

  const updateLayer = () => {
    if (!mapa_layer.current || !mapa.current) return;
    nomarker.current?.classList.remove("active");

    //Pouze při prvním volání se zapne vykreslování podkladů
    if (!rendered.current) {
      mapa.current.addDefaultLayer(window.SMap.DEF_BASE).enable();
      mapa.current.addDefaultControls();
      rendered.current = true;
    }

    mapa_layer.current.removeAll();

    // Přidá marker
    const position = window.SMap.Coords.fromWGS84(parseFloat(gps_data[1]), parseFloat(gps_data[0]));
    const marker = new window.SMap.Marker(position, null, null);
    mapa_layer.current.addMarker(marker);
    mapa.current.setCenter(position);
  };

  const onResize = useCallback(() => {
    if (rendered.current) {
      if (resize_timer.current !== null) clearTimeout(resize_timer.current);
      resize_timer.current = setTimeout(() => {
        mapa.current.syncPort();
      }, 200);
    }
  }, []);

  // Aktualizace umístění při změně dat
  useEffect(() => {
    if (refresh_timer.current !== null) clearTimeout(refresh_timer.current);

    if (rendered.current) {
      //Kvůli rychlému procházení v gridu
      refresh_timer.current = setTimeout(updateData, 500);
    }
  }, [data]);

  // Načtení při otevření komponenty
  useEffect(() => {
    const onload = () => {
      window.Loader.async = true;
      window.Loader.load(null, null, createMap);
    };

    const script = document.createElement("script");
    script.src = "https://api.mapy.cz/loader.js";
    script.async = true;
    script.onload = onload;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
      <div className={css.map} ref={element} style={StyleHelper(control, props.style)}>
        <div className={css.map_nomarker} ref={nomarker}>
          Nenalezeno
        </div>
        {/* <div className={css.map_mobile_message} ref={mobile_message}>
          Pro pohyb na mapě použij dva prsty současně
        </div> */}
        <a className={css.map_link} title="Otevřít pozici na Mapy.CZ" ref={maplink} target="_blank">
          <K2Img glyphId="wui*editor.link.svg" style={{ textAlign: "center", alignSelf: "center" }} height={15} width={15} />
        </a>
      </div>
    </ReactResizeDetector>
  );
};

export default K2Map;
