import { List } from "immutable";
import React, { useEffect, useRef, useState } from "react";
import { DraggableData, DraggableEvent } from "react-draggable";
import { Position, ResizableDelta, Rnd } from "react-rnd";
import { ResizeDirection } from "re-resizable";
import { Log, Size } from "../../common/common";
import { CSNclViewMetadata, FrameStyle, ModalPosition, RectInDockPositionMode, RectInDockSizeMode, UpdateHeadered } from "../../common/communication.base";
import { NclColorPicker, NclFloaterView, NclInplaceView, NclOpenDialog, NclViewBase, UFOverAlign } from "../../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import { K2Header } from "../Expander/K2Expander";
import { AcquireControl, WithVCXProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import css from "./Modal.scss";

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
  headerTitle: string;
  realizersQueue?: List<RealizerQueueItem>;
  state: UpdateHeadered;
  updateModalList?: () => void;
}

const K2ModalWindow = (props: SimpleModalWindowProps) => {
  const control = AcquireControl(props.controlUID, props.realizerUID, (ctrl) => ctrl instanceof NclViewBase) as NclViewBase<CSNclViewMetadata, UpdateHeadered>;
  const vr = ViewRealizerManager.getViewRealizer(props.realizerUID);
  const rectInDock = control.getRectInDock();
  const backdrop = useRef<HTMLDivElement>(null);
  const [translate, setTranslate] = useState<ModalPosition>({ x: 0, y: 0 });
  const [size, setSize] = useState<{ height: string; width: string }>({ height: "0px", width: "0px" });
  let anchor: UFOverAlign;
  let anchored: UFOverAlign;

  useEffect(() => {
    const size = getSize();
    setSize({ height: size.height, width: size.width });
  }, [props.state.isMaximized]);

  useEffect(() => {
    if (props.state.isDefaultSizeAndPosition) {
      handleDefaultState();
    }
  }, [props.state.isDefaultSizeAndPosition]);

  useEffect(() => {
    handleDefaultState();

    props.realizersQueue?.map((r) => {
      if (!r.isOpen) {
        props.updateModalList?.();
      }
    });
  }, [props.realizersQueue]);

  if (rectInDock.AnchorControlUID) {
    let viewrealizer: ViewRealizer = control instanceof NclFloaterView ? vr : vr.getPriorRealizer();

    if (!viewrealizer) {
      viewrealizer = vr;
    }

    if (viewrealizer) {
      const ctrl = viewrealizer.getControlByUID(rectInDock.AnchorControlUID);

      if (ctrl != null && ctrl.Listener && ctrl.Listener) {
        const listener: UFOverAlign = ctrl.Listener;

        if (listener.getOverRect) {
          anchor = listener;
        } else {
          Log.warn("Not exist component fot attach element" + rectInDock.AnchorControlUID + ".");
        }
      } else {
        Log.warn("Not exist attach element" + rectInDock.AnchorControlUID + ".");
      }
    } else {
      Log.error("Not exist realizer for find attach element" + rectInDock.AnchorControlUID + ".", null);
    }
  }

  function getSize() {
    if (control instanceof NclColorPicker) return { height: "auto", width: "auto" };
    let height = "";
    let width = "";

    if (backdrop.current) {
      if (props.state.isMaximized === true) {
        height = "100%";
        width = "100%";
      } else {
        const initS = getInitialSize();
        const contentS = calcSizeByContent();

        if (initS.height === contentS.height) {
          if (control instanceof NclOpenDialog) {
            height = "auto";
          } else {
            height = initS.height + "px";
          }
        } else {
          if (rectInDock.SizeMode === RectInDockSizeMode.ridsmPercent && rectInDock.Height > 0) {
            height = rectInDock.Height + "%";
          } else {
            height = initS.height + "px";
          }
        }

        if (initS.width === contentS.width) {
          width = initS.width + "px";
        } else {
          if (rectInDock.SizeMode === RectInDockSizeMode.ridsmPercent && rectInDock.Width > 0) {
            width = rectInDock.Width + "%";
          } else {
            width = initS.width + "px";
          }
        }
      }
    }

    return { height, width };
  }

  function defaultPosition(): ModalPosition {
    if (control && !props.state.isMaximized) {
      const position = control.getExplicitPosition();

      if (position) {
        correctTranslate(position);

        return position;
      }
    }

    return { x: 0, y: 0 };
  }

  function handleDefaultState() {
    let translate = defaultPosition();

    if (backdrop.current) {
      if (translate.x + translate.y === 0) {
        const overlayRect = backdrop.current.getBoundingClientRect();

        if (anchor) {
          const anchorRect = anchor.getOverRect();

          if (overlayRect && anchorRect) {
            translate = calcPosition(anchorRect, overlayRect);
          }
        } else {
          translate = computeCenterTranslate(backdrop.current.getBoundingClientRect(), getInitialSize()); //calc safe position in center
        }

        correctTranslate(translate);
      }
    }

    const size = getSize();
    setTranslate(translate);
    setSize({ height: size.height, width: size.width });

    if (control) {
      control.clearExplicitBoundsRequest();
    }
  }

  //Posun modálního okna v případě přesahu
  function correctTranslate(position: ModalPosition) {
    if (backdrop.current) {
      const overlayRect = backdrop.current.getBoundingClientRect();
      const size = getInitialSize();

      //Posun modálního okna v případě přesahu
      if (size.width + position.x > overlayRect.width) {
        position.x -= size.width + position.x - overlayRect.width;
      }

      if (size.height + position.y > overlayRect.height) {
        position.y -= size.height + position.y - overlayRect.height + 10;
      }

      if (position.y < 0) position.y = 0;
    }
  }

  function calcPosition(anchorRect: DOMRect, overlayRect: DOMRect): ModalPosition {
    let result = computeCenterTranslate(document.body.getBoundingClientRect(), getInitialSize());

    if (backdrop.current) {
      result = computeCenterTranslate(backdrop.current.getBoundingClientRect(), getInitialSize());
    }

    if (control) {
      const rd = control.getRectInDock();

      if (rd && rd.PositionMode !== RectInDockPositionMode.ridpmCenter) {
        let anchoredRect = undefined;

        switch (rd.PositionMode) {
          case RectInDockPositionMode.ridpmAchorCenter:
            result = computeCenterTranslate(anchorRect, getInitialSize());
            break;
          case RectInDockPositionMode.ridpmAnchorOutside:
            result = {
              x: anchorRect.left - overlayRect.left - control.VCX.Data.MarginX,
              y: anchorRect.bottom - overlayRect.top + control.VCX.Data.MarginY,
            };
            break;
          case RectInDockPositionMode.ridpmAnchorOverAtLeft:
          case RectInDockPositionMode.ridpmAnchorOverAtRight:
            if (!anchored) {
              const ac = vr.getAnchoredControl();

              if (ac && (ac.Listener as UFOverAlign)?.getOverRect) {
                anchored = ac.Listener;
              }
            }

            if (anchored && anchored.getOverRect) {
              anchoredRect = anchored.getOverRect();
            }

            if (anchoredRect) {
              if (rd.PositionMode === RectInDockPositionMode.ridpmAnchorOverAtRight && anchoredRect.width > anchorRect.width) {
                return { x: anchorRect.right - anchoredRect.width - overlayRect.left, y: anchorRect.top - overlayRect.top };
              }

              return { x: anchorRect.left - overlayRect.left, y: anchorRect.top - overlayRect.top };
            } else {
              if (rd.PositionMode === RectInDockPositionMode.ridpmAnchorOverAtLeft) {
                return { x: 0, y: anchorRect.top - overlayRect.top };
              } else {
                const size = getInitialSize();

                if (size) {
                  return { x: overlayRect.right - size.width, y: anchorRect.top - overlayRect.top };
                }
              }
            }

            result = { x: anchorRect.left - overlayRect.left, y: anchorRect.top - overlayRect.top };

            break;
        }
      }
    }

    return result;
  }

  function getInitialSize(): Size {
    if (control instanceof NclColorPicker) {
      return { width: 300, height: 330 };
    }
    if (control) {
      const s = control.getExplicitSize();

      if (s) {
        return s;
      }
    }

    const size = calcSizeByContent();

    switch (rectInDock.SizeMode) {
      case RectInDockSizeMode.ridsmAnchorWidth:
      case RectInDockSizeMode.ridsmAnchorWidthMinOriginal:
        return calcAnchorSize(size);
      case RectInDockSizeMode.ridsmPercent:
        return calcPercentSize(size);
      case RectInDockSizeMode.ridsmOriginal:
      default:
        return size;
    }
  }

  function calcSizeByContent(): Size {
    if (!control) return { height: 0, width: 0 };

    let fullHeight = control.calcFullHeight();
    let width = control.MetaData.FrgtData.BandsCount * control.VCX.MinRowHeight * control.Ncl.FrgtData.WHRatio;

    if (backdrop.current) {
      const rect = backdrop.current.getBoundingClientRect();

      if (fullHeight >= rect.height) {
        fullHeight = rect.height * 0.96;
      }

      if (width >= rect.width) {
        width = rect.width * 0.98;
      }
    }

    return { width: width, height: fullHeight };
  }

  function calcAnchorSize(size: Size): Size {
    const result: Size = Object.assign({}, size);

    if (!anchor) return result;

    const rect = anchor.getOverRect();

    const overMode =
      rectInDock.SizeMode === RectInDockSizeMode.ridsmAnchorWidth &&
      (rectInDock.PositionMode == RectInDockPositionMode.ridpmAnchorOverAtLeft || rectInDock.PositionMode == RectInDockPositionMode.ridpmAnchorOverAtRight);
    if (rect && overMode) {
      result.width = rect.width;
    }

    if (rect && rectInDock.SizeMode === RectInDockSizeMode.ridsmAnchorWidthMinOriginal) {
      if (result.width < rect.width) {
        result.width = rect.width;
      }
    }

    return result;
  }

  function calcPercentSize(size: Size): Size {
    const result: Size = Object.assign({}, size);

    if (!backdrop.current) return result;

    const rect = backdrop.current.getBoundingClientRect();

    if (rectInDock.Width >= 0) {
      result.width = Math.round(rect.width * (rectInDock.Width / 100));
    }
    if (rectInDock.Height >= 0) {
      result.height = Math.round(rect.height * (rectInDock.Height / 100));
    }

    return result;
  }

  function computeCenterTranslate(inRect: DOMRect, size: Size) {
    if (!inRect) return { x: 0, y: 0 };
    if (size.height < 0 && size.height < 0) return { x: 0, y: 0 };

    const sizeByContent = calcSizeByContent();
    const x = Math.round((inRect.width - Math.max(size.width, sizeByContent.width)) / 2);
    const y = Math.round((inRect.height - Math.max(size.height, sizeByContent.height)) / 2);

    return { x: x, y: y };
  }

  function getPosition() {
    if (size.width === "0px" && size.height === "0px") {
      return { x: -200, y: -200 };
    }

    if (props.state.isMaximized) {
      return { x: 0, y: 0 };
    }

    return { x: Math.round(translate.x), y: Math.round(translate.y) };
  }

  function handleOutsideClick(e: React.MouseEvent<HTMLDivElement>) {
    if (rectInDock.EffectiveMouseClickClose) {
      if (e.target === backdrop.current) {
        //nevim jestli pro to není lepší způsob, prozatím takto
        control.closeRequest();

        if (e.type === "contextmenu") {
          e.preventDefault();
        }
      }
    }
  }

  function handleDragStop(e: DraggableEvent, data: DraggableData) {
    if (e.target instanceof HTMLButtonElement) return;

    control.moveView(data.x, data.y);
    setTranslate({ x: data.x, y: data.y });
  }

  function handleResizeStop(e: MouseEvent | TouchEvent, dir: ResizeDirection, element: HTMLElement, delta: ResizableDelta, position: Position) {
    control.resizeView(element.offsetHeight, element.offsetWidth);
    control.moveView(position.x, position.y);
    setSize({ height: `${element.offsetHeight}px`, width: `${element.offsetWidth}px` });
    setTranslate({ x: position.x, y: position.y });
  }

  function isResizingAllowed() {
    return [FrameStyle.frsFrameResize, FrameStyle.frsTitleResize].includes(control.getRectInDock().FrameStyle);
  }

  let content = (
    <div style={{ zIndex: vr.getDepth() * 10 }} className={css.modal}>
      {control.isShowHeader() && (
        <K2Header
          controlUID={control.Header.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          title={props.headerTitle}
          className={"handle_" + control.MetaData.ControlUID}
        />
      )}
      {props.children}
    </div>
  );

  let minHeight = control.VCX.InputControl.getInputHeight(1, true, false);

  if (control instanceof NclInplaceView) {
    minHeight = control.VCX.GridControl.GetRowHeight(1);
  } else {
    if (control.Header) minHeight = control.Header.ComputedMinHeight;
  }

  content = (
    <Rnd
      default={{ height: 0, width: 0, x: 0, y: 0 }}
      size={{ height: size.height, width: size.width }}
      minWidth={100}
      minHeight={minHeight}
      bounds="parent"
      position={{ x: getPosition().x, y: getPosition().y }}
      cancel=".no-drag"
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      enableResizing={isResizingAllowed()}
    >
      {content}
    </Rnd>
  );

  return (
    <div
      ref={backdrop}
      onClick={handleOutsideClick}
      onContextMenu={handleOutsideClick}
      className={css.modal_backdrop}
      style={{
        zIndex: vr.getDepth() * 5,
        backgroundColor: props.isOverlayBck ? control.VCX.getRGBColor(control.VCX.Data.ColorMap.AlphaColor).toHTML(0.2) : undefined,
      }}
    >
      {content}
    </div>
  );
};

export default K2ModalWindow;
