import { EventInput } from "@fullcalendar/core";
import { CSUFNclAppointment, CSUFTNclProvider, TLanguageCodeList, UpdateCalendar } from "../../common/communication.base";

export interface ExtendedProps {
  K2Pk: string;
  SerieUI: string;
  AppointmentItemType: number;
  AppointmentId: string;
  ProviderDMClassname: string;
  Hint: string;
  AllowDelete: boolean;
  AllowDragAndDrop: boolean;
  AllowInsert: boolean;
  StateGlyphId: string;
  StateIconJSX: JSX.Element;
}

export function GetEvents(state: UpdateCalendar) {
  const lEvents: EventInput[] = [];

  state.ProvidersList?.forEach((lOneProvider: CSUFTNclProvider) => {
    lOneProvider.AppointmentsList.forEach((Appointment: CSUFNclAppointment) => {
      const lSingleEvent = {
        start: new Date(Appointment.StartDate),
        end: endDateCorrection(new Date(Appointment.EndDate), Appointment.IsAllDayEvent, "add"),
        allDay: Appointment.IsAllDayEvent,
        title: Appointment.Subject,
        backgroundColor: Appointment.CustomColor ? Appointment.CustomColor : lOneProvider.Color,
        extendedProps: {
          K2Pk: Appointment.K2Pk,
          SerieUI: lOneProvider.ProviderID,
          AppointmentItemType: Appointment.AppointmentItemType,
          AppointmentId: Appointment.AppointmentId,
          ProviderDMClassname: lOneProvider.ProviderDMClassname,
          Hint: Appointment.Hint,
          AllowDelete: lOneProvider.AllowDelete,
          AllowDragAndDrop: lOneProvider.AllowDragAndDrop,
          AllowInsert: lOneProvider.AllowInsert,
          StateGlyphId: Appointment.StateGlyphId,
        },
        editable: lOneProvider.AllowDragAndDrop,
        priority: Appointment.Priority,
      };

      lEvents.push(lSingleEvent);
    });
  });

  lEvents.sort((a, b) => {
    const dateA = new Date(a?.start.toString());
    const dateB = new Date(b?.start.toString());

    // Handle cases where either a or b is null or undefined
    if (!dateA && !dateB) {
      return 0; // No change in order if both are invalid dates
    }
    if (!dateA) {
      return 1; // Put invalid dates at the end
    }
    if (!dateB) {
      return -1; // Put invalid dates at the end
    }

    // Compare the valid dates
    return dateA.getTime() - dateB.getTime();
  });

  return lEvents;
}

export function endDateCorrection(endDay: Date, allDay: boolean, correction: "add" | "subtract", startDay?: Date): Date {
  const today = 1000 * 60 * 60 * 24;

  if (!endDay) {
    return startDay;
  }

  if (allDay) {
    if (correction === "add") {
      return new Date(endDay.getTime() + today);
    } else {
      return new Date(endDay.getTime() - today);
    }
  } else {
    return endDay;
  }
}

export function ConvertToIndependentFormat(aDate: Date): string {
  if (aDate == null) {
    return " 0000-00-00 00:00:00:000";
  } else {
    const lMonthAsN = aDate.getMonth() + 1;
    const lMonth = ("00" + lMonthAsN.toString()).slice(-2);
    const lDate = ("00" + aDate.getDate().toString()).slice(-2);
    const lDateAsStr = aDate.getFullYear() + "-" + lMonth + "-" + lDate;

    const lHour = ("00" + aDate.getHours().toString()).slice(-2);
    const lMin = ("00" + aDate.getMinutes().toString()).slice(-2);
    const lSec = ("00" + aDate.getSeconds().toString()).slice(-2);
    const lMili = ("000" + aDate.getMilliseconds().toString()).slice(-3);
    const lTimeAsStr = lHour + ":" + lMin + ":" + lSec + ":" + lMili;

    return lDateAsStr + " " + lTimeAsStr;
  }
}

export function K2CalendarStyles(colors: {
  AccentBaseColorBck: string;
  GridRulerColorBck: string;
  BaseColorBck1: string;
  ContentColorBck1: string;
  ContentChangeDecorateColorFrg: string;
  BaseColorFrg1: string;
}) {
  return `
  .fc .fc-daygrid-day-frame,
  .fc .fc-daygrid-body-balanced .fc-daygrid-day-events,
  .fc .fc-daygrid-event-harness,
  .fc .fc-daygrid-body-natural .fc-daygrid-day-events,
  .fc .fc-more-popover,
  .fc .fc-more-popover .fc-popover-body,
  .fc-event .fc-event-main,
  .fc-time-and-event,
  .fc .fc-scroller-liquid-absolute {
    display: block;
  }

	.fc .fc-toolbar.fc-header-toolbar {
		background-color: ${colors.AccentBaseColorBck};
		color: ${colors.BaseColorFrg1};
	}

	.fc .fc-button-group > .fc-button {
		background-color: ${colors.AccentBaseColorBck};
		border-color: ${colors.AccentBaseColorBck};
	}

	.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
		background-color: ${colors.ContentChangeDecorateColorFrg};
		border-color: ${colors.ContentChangeDecorateColorFrg};
	}

	.fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:focus {
		box-shadow: 0 0 0 0 transparent;
	}

	table.fc-scrollgrid-liquid > thead {
		background-color: ${colors.ContentColorBck1};
	}

	table.fc-scrollgrid-liquid > tbody {
		background-color: ${colors.BaseColorFrg1};
	}

	.fc .fc-col-header-cell-cushion {
		font-weight: normal;
		color: ${colors.BaseColorBck1};
	}

  .fc .fc-daygrid-day-number {
		color: black;
	}

	.fc .fc-toolbar.fc-header-toolbar {
		margin-bottom: 0;
	}

	.fc .fc-daygrid-day.fc-day-today, .fc .fc-timegrid-col.fc-day-today {
		background-color: ${colors.GridRulerColorBck};
	}

	.fc-day-today .fc-daygrid-day-number {
		font-weight: bold;
	}

	.fc .fc-daygrid-event {
		padding: 2px 4px;
	}

	.fc-direction-ltr .fc-daygrid-event.fc-event-end ul {
		list-style-position: inside;
	}

	.fc-event-content {
		width: 100%;
    display: flex;
		justify-content: space-between;
	}

	.fc-event-info {
		flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
	}

	.fc-event-info-not-all-day {
    display: flex;
		align-items: center;
	}

	.fc-event-name {
		font-weight: bold;
	}

	.fc-circle {
		border: 1px solid ${colors.AccentBaseColorBck};
		background-color: ${colors.AccentBaseColorBck};
		border-radius: 50%;
		width: 8px;
		height: 8px;
		margin-right: 5px;
		flex: 0 0 auto;
	}

	@media only screen and (max-width: 980px) {
		.fc-circle, .fc-event-time {
			display: none !important;
		}
	}

	.fc .fc-toolbar-title, .fc .fc-col-header-cell-cushion {
		text-transform: capitalize;
	}

	.fc .fc-button {
		overflow: hidden;
  }
  
  .fc-button-delete-wrapper {
    flex: 0 0 auto;
    display: flex;
  }

	.fc-button-delete-event {
		border: none;
		background-color: inherit;
		color: inherit;
	}

	.fc-v-event .fc-event-time {
		display: block;
	}

	.fc-timegrid-cols {
		z-index: 2;
	}

	.fc-direction-ltr .fc-daygrid-event.fc-event-end {
		color: unset;
	}

  .fc-state-icon {
    margin-right: 3px;
    align-items: center;
  }

  .fc-event-main {
    overflow: hidden;
  }

  .fc-timegrid-event .fc-event-name {
    white-space: normal;
  }

  button:hover::after,
  button:focus-visible::after,
  button:active::after {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
  }

  @media (hover: hover) {
    button:hover::after {
      background-color: rgba(0, 0, 0, 0.17);
    }
  }

  button:focus-visible::after {
    background-color: rgba(0, 84, 166, 0.3);
  }

  button:active::after {
    background-color: rgba(0, 0, 0, 0.5);
  }

  button[disabled]:hover::after {
    background-color: transparent;
  }
`;
}

export const getLocale = (locale: TLanguageCodeList) => {
  switch (locale) {
    case TLanguageCodeList.lclImplicit:
      return navigator.language;
    case TLanguageCodeList.lclEnglish:
      return "en";
    case TLanguageCodeList.lclGerman:
      return "de";
    case TLanguageCodeList.lclSlovak:
      return "sk";
    case TLanguageCodeList.lclFrench:
      return "fr";
    case TLanguageCodeList.lclCzech:
      return "cs";
    case TLanguageCodeList.lclHungarian:
      return "hu";
    case TLanguageCodeList.lclPolish:
      return "pl";
    case TLanguageCodeList.lclItalian:
      return "it";
    case TLanguageCodeList.lclSpanish:
      return "es";
    case TLanguageCodeList.lclRussian:
      return "ru";
    case TLanguageCodeList.lclAlbanian:
      return "sq";
    case TLanguageCodeList.lclBelorussian:
      return "be";
    case TLanguageCodeList.lclBosnian:
      return "bs";
    case TLanguageCodeList.lclBulgarian:
      return "bg";
    case TLanguageCodeList.lclCatalanian:
      return "ca";
    case TLanguageCodeList.lclCroatian:
      return "hr";
    case TLanguageCodeList.lclDanish:
      return "da";
    case TLanguageCodeList.lclDutch:
      return "nl";
    case TLanguageCodeList.lclGreek:
      return "el";
    case TLanguageCodeList.lclEstonian:
      return "et";
    case TLanguageCodeList.lclFinnish:
      return "fi";
    case TLanguageCodeList.lclGallic:
      return "gd";
    case TLanguageCodeList.lclIcelandic:
      return "is";
    case TLanguageCodeList.lclLatvian:
      return "lv";
    case TLanguageCodeList.lclLithuanian:
      return "lt";
    case TLanguageCodeList.lclLuxembourgian:
      return "lb";
    case TLanguageCodeList.lclMacedonian:
      return "mk";
    case TLanguageCodeList.lclMaltaian:
      return "mt";
    case TLanguageCodeList.lclMoldovian:
      return "ro";
    case TLanguageCodeList.lclNorwegian:
      return "nb";
    case TLanguageCodeList.lclPortugese:
      return "pt";
    case TLanguageCodeList.lclRetoromanian:
      return "rm";
    case TLanguageCodeList.lclRomanian:
      return "ro";
    case TLanguageCodeList.lclSerbian:
      return "sr";
    case TLanguageCodeList.lclSlovene:
      return "sl";
    case TLanguageCodeList.lclSwedish:
      return "sv";
    case TLanguageCodeList.lclTurkish:
      return "tr";
    case TLanguageCodeList.lclUkrainian:
      return "uk";
    case TLanguageCodeList.lclArabic:
      return "ar";
    case TLanguageCodeList.lclFilipino:
      return "fil";
    case TLanguageCodeList.lclHebrew:
      return "he";
    case TLanguageCodeList.lclIndonesian:
      return "id";
    case TLanguageCodeList.lclJapanese:
      return "ja";
    case TLanguageCodeList.lclKorean:
      return "ko";
    case TLanguageCodeList.lclMalaysian:
      return "ms";
    case TLanguageCodeList.lclMandarin:
      return "mdr";
    case TLanguageCodeList.lclMongolian:
      return "mn";
    case TLanguageCodeList.lclPersian:
      return "fa";
    case TLanguageCodeList.lclSomalian:
      return "so";
    case TLanguageCodeList.lclSwahilli:
      return "sw";
    case TLanguageCodeList.lclVietnamese:
      return "vi";
    case TLanguageCodeList.lclZhonguoHua:
      return "zh";
    case TLanguageCodeList.lclChinese:
      return "zh";
    case TLanguageCodeList.lclFlemishDutch:
      return "nl";
    case TLanguageCodeList.lclBelgianFrench:
      return "fr-be";
    default:
      return "en";
  }
};
