import React, { useEffect, useRef, useState } from "react";
import { CancelablePromise, Log, makeCancelable } from "../../common/common";
import { NclImage } from "../../common/components.ncl";
import { MediaData, MediaManager } from "../../common/mediaManager";
import { VisualContext } from "../../common/visualContext";
import css from "./Image.scss";

interface ImgProps {
  glyphId: string;
  height?: number;
  width?: number;
  strokeColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  stretch?: boolean;
  className?: string;
  k2Id?: string;
  nonSvgHeight?: number;
  vcx?: VisualContext;
}

const notFoundSvg =
  '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 20.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="error.invert" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32" xml:space="preserve" xmlns:k2svg="http://www.k2.cz/svg">' +
  '<path id="error.invert_1_" d="M16,2c7.7,0,14,6.3,14,14s-6.3,14-14,14S2,23.7,2,16S8.3,2,16,2 M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16&#xA;	s16-7.2,16-16S24.8,0,16,0L16,0z M24.5,8.9l-1.4-1.4L16,14.6L8.9,7.5L7.5,8.9l7.1,7.1l-7.1,7.1l1.4,1.4l7.1-7.1l7.1,7.1l1.4-1.4&#xA;	L17.4,16L24.5,8.9z"/></svg>';

const K2Img = (props: ImgProps) => {
  const promise = useRef<CancelablePromise<MediaData>>();
  const [svg, setSvg] = useState("");
  let img: JSX.Element | undefined;

  const isSVG = () => {
    if (svg) return true;

    return NclImage.isSVG(props.glyphId);
  };

  const loadImage = () => {
    if (!isSVG()) return;

    promise.current = makeCancelable<MediaData>(MediaManager.getSVG(props.glyphId));

    promise.current.promise
      .then((svg) => {
        setSvg(svg.content);
      })
      .catch((reason) => {
        if (reason && !reason.isCanceled) {
          Log.error("Svg error: " + reason, null);
          setSvg(notFoundSvg);
        }
      });
  };

  useEffect(() => {
    loadImage();
  }, [props.glyphId]);

  useEffect(() => {
    return () => {
      if (promise.current) {
        promise.current.cancel();
      }
    };
  }, []);

  if (!props.glyphId || props.glyphId === "") return null;

  if (isSVG()) {
    if (svg) {
      img = (
        <div
          data-k2-test-id={props.k2Id}
          dangerouslySetInnerHTML={{ __html: svg }}
          style={{ backgroundColor: props.backgroundColor, fill: props.strokeColor ? props.strokeColor : "currentColor" }}
          className={css.svg}
        />
      );
    }
  } else {
    const src = NclImage.getSourceUrl(props.glyphId, props.nonSvgHeight ? props.nonSvgHeight : props.height);

    img = <img className={css.non_svg} src={src?.url} data-k2-test-id={props.k2Id} onError={() => setSvg(notFoundSvg)}></img>;
  }

  let className = css.img;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div
      className={className}
      style={{
        position: "relative",
        ...props.style,
        height: props.height ? props.height + "px" : "100%",
        width: props.width ? props.width + "px" : props.style?.width ? props.style.width : "100%",
        backgroundColor: props.backgroundColor,
        flex: props.width ? "0 1 auto" : "1 1 auto",
      }}
    >
      {img}
    </div>
  );
};

export default K2Img;
