import React, { useEffect } from "react";
import { UpdateAggregationPanel, CSAggrData, ColumnProportionEm } from "../../common/communication.base";
import { NclAggregationPanel } from "../../common/components.ncl";
import { WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import K2ColGroup from "./K2ColGroup";
import { ColumnsProps, FixedColumnOffset, getFixedColumnStyles, getTextAlign } from "./utils";
import css from "./DataGrid.scss";
import { useServerState } from "../hooks";

interface AggregationPanelProps extends WithContextPlacementProps, ColumnsProps {
  width: number;
  scrollLeft: number;
  getFixedColumnOffset: FixedColumnOffset;
}

const AggregationPanel = (props: AggregationPanelProps) => {
  const [control, data, element] = useServerState<NclAggregationPanel, UpdateAggregationPanel, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclAggregationPanel
  );

  useEffect(() => {
    if (!element.current) return;

    element.current.scrollLeft = props.scrollLeft;
  }, [props.scrollLeft]);

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    control.contextMenu(index);
    e.preventDefault();
  };

  if (!data.Data || data.Visible !== true) return null;

  const values: Array<CSAggrData> = data.Data.toJS() as CSAggrData[];

  return (
    <div ref={element} className={`${css.dg_aggregation_wrapper}${data.InvalidData ? ` ${css.dg_invalid_data}` : ""}`}>
      <table tabIndex={1} className={css.dg_aggregation} style={{ width: props.width + "px" }}>
        {props.columnsProportion && (
          <K2ColGroup
            columnsProportion={props.columnsProportion}
            fixedColumnsCount={props.fixedColumnsCount}
            columnsWidths={props.columnsWidths}
            columnsVersion={props.columnsVersion}
          />
        )}
        <tbody>
          <tr>
            {values.map((value: CSAggrData, index: number) => {
              if (props.columnsProportion && props.columnsProportion.length > index) {
                const proportion = props.columnsProportion[index];

                return (
                  <th
                    key={"column_aggr_" + index}
                    className={css.dg_aggregation_column + `${index < props.fixedColumnsCount ? " dg_fixed_col" : ""}`}
                    style={getFixedColumnStyles(proportion.Width, props.getFixedColumnOffset(props.columnsWidths, index, index < props.fixedColumnsCount))}
                    onContextMenu={(e) => handleContextMenu(e, index)}
                  >
                    <div className={css.dg_aggregation_cell}>
                      {value.GlyphId && (
                        <K2Img
                          glyphId={value.GlyphId}
                          vcx={control.VCX}
                          height={control.VCX.sizeMap(16)}
                          width={control.VCX.sizeMap(16)}
                          strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
                        />
                      )}
                      <K2TruncateText style={{ width: "100%", textAlign: getTextAlign(value.Align), alignSelf: "center", fontWeight: "normal" }}>
                        {value.Text.toString()}
                      </K2TruncateText>
                    </div>
                  </th>
                );
              }
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AggregationPanel;
