import React from "react";

/*
  itStart: Result := 'wui*raunded.square.green.play';
  itEnd: Result := 'wui*raunded.square.red.stop';
  itTransfer: Result := 'wui*raunded.square.green.white.square';
  itCase: Result := 'wui*raunded.square.green.white.rhombus';
  itDistrib: Result := 'wui*raunded.square.green.white.triangle';
  itSubModel: Result := 'wui*raunded.square.orange.sub.process';
  itAcknowledge: Result := 'wui*raunded.square.blue.info';)
*/

export const SvgStepEnd = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-144)">
        <g transform="matrix(1,0,0,1,576,144)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(244,86,86)" }}
          />
        </g>
        <g id="surface1" transform="matrix(1,0,0,1,576,144)">
          <path d="M6,6L6,26L26,26L26,6L6,6ZM8,8L24,8L24,24L8,24L8,8Z" style={{ fill: "white", fillRule: "nonzero" }} />
        </g>
        <g transform="matrix(1,0,0,1,96,32)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es běží
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepStart = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-80)">
        <g transform="matrix(1,0,0,1,576,80)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(61,195,124)" }}
          />
        </g>
        <g id="surface1" transform="matrix(1,0,0,1,576.156,80)">
          <path
            d="M9,5.156L9,26.844L10.531,25.844L25.844,16L10.531,6.156L9,5.156ZM11,8.813L22.156,16L11,23.188L11,8.813Z"
            style={{ fill: "white", fillRule: "nonzero" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,96,-32.2969)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je uk
            <tspan x="338.708px 346.16px " y="121.623px 121.623px ">
              on
            </tspan>
            čen
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepTransfer = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-336)">
        <g transform="matrix(1,0,0,1,80.105,0.296875)">
          <path d="M681.98,361.683L681.98,341.723L662.02,341.723L662.02,361.683L681.98,361.683Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepCase = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-400)">
        <g transform="matrix(1,0,0,1,80.105,-0.11377)">
          <path d="M686.114,416L672,401.886L657.886,416L672,430.114L686.114,416Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepDistrib = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-464)">
        <g transform="matrix(1,0,0,1,76.9799,128.297)">
          <path d="M683.98,363.683L683.98,339.723L664.02,351.723L683.98,363.683Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepSubModel = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-272)">
        <g transform="matrix(1,0,0,1,576,272)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(255,136,0)" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,576,272)">
          <path
            d="M17,8.515L23.485,15L27,15L27,17L23.485,17L17,23.485L17,27L15,27L15,23.485L8.515,17L5,17L5,15L8.515,15L15,8.515L15,5L17,5L17,8.515ZM10.343,16L16,21.657L21.657,16L16,10.343L10.343,16Z"
            style={{ fill: "white" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,96,160)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je pozastaven
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepAcknowledge = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-208)">
        <g transform="matrix(1,0,0,1,576,208)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(0,159,227)" }}
          />
        </g>
        <g id="i" transform="matrix(1,0,0,1,576,208)">
          <path d="M15,26L17,26L17,11L15,11L15,26ZM15,6L17,6L17,8L15,8L15,6Z" style={{ fill: "white", fillRule: "nonzero" }} />
        </g>
        <g transform="matrix(1,0,0,1,96,96.2969)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je st
            <tspan x="335.007px 342.459px " y="121.623px 121.623px ">
              or
            </tspan>
            nov
            <tspan x="367.266px 373.778px " y="121.623px 121.623px ">
              án
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
