import React from "react";
import { IconPosition, UpdatePageControl } from "../../common/communication.base";
import { NclPage, NclVRTabControl } from "../../common/components.ncl";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./Tab.scss";
import { useServerState } from "../hooks";

interface TabProps extends WithContextPlacementProps {
  lastDynamicPage?: string;
  showIcon?: boolean;
  onClick?: React.Dispatch<React.SetStateAction<boolean>>;
}

const K2Tab = (props: TabProps) => {
  const [control, data, element] = useServerState<NclPage, UpdatePageControl, HTMLDivElement>(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclPage);
  const parentTab = control.getTabParent();

  const getGlyphId = () => {
    if (data.GlyphId) return data.GlyphId;
    return control.MetaData.FrgtData.Icon;
  };

  const isStaticVRTab = () => {
    if (parentTab instanceof NclVRTabControl && !control.Ncl.IsDynamic) {
      return true;
    }

    return false;
  };

  const getPageCount = () => {
    let pageCount = 0;

    if (parentTab instanceof NclVRTabControl) {
      pageCount = parentTab.Pages.filter((page) => page.Ncl.IsDynamic).size;
    } else {
      pageCount = parentTab.Pages.size;
    }

    return pageCount;
  };

  const handleClick = () => {
    if (window.innerWidth < 980 && getPageCount() > 1 && parentTab.State.CurrentPage === control.Ncl.PageUID && !isStaticVRTab() && props.onClick) {
      props.onClick(true);
    } else {
      parentTab.changeCurrentPage(control.Ncl.PageUID);
    }
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    if (!(parentTab instanceof NclVRTabControl)) return;

    e.stopPropagation();
    parentTab.closePage(control.Ncl.PageUID);
  };

  const handleContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    control.showContextMenu();
  };

  const getFlexDirection = () => {
    let flexDirection: string;

    switch (parentTab.Ncl.FrgtData.TabIconPosition) {
      case IconPosition.ipTop:
        flexDirection = css.tc_column;
        break;
      case IconPosition.ipBottom:
        flexDirection = css.tc_column_reverse;
        break;
      case IconPosition.ipLeft:
        flexDirection = css.tc_row;
        break;
      case IconPosition.ipRight:
        flexDirection = css.tc_row_reverse;
        break;
      default:
        flexDirection = css.tc_row;
        break;
    }

    return flexDirection;
  };

  const renderExpandIcon = () => {
    if (getPageCount() > 1 && !isStaticVRTab() && (parentTab.State.CurrentPage === control.Ncl.PageUID || props.lastDynamicPage === control.Ncl.PageUID)) {
      return true;
    } else {
      return false;
    }
  };

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (props.onClick) {
      props.onClick(true);
    }
  };

  if (!data || data.Hide || !control.Content) return null;

  return (
    <>
      <div
        ref={element}
        style={StyleHelper(control, props.style)}
        className={`${props.onClick ? "button" : ""} ${props.className} ${getFlexDirection()}`}
        onClick={handleClick}
        onContextMenu={handleContextMenu}
        data-k2-test-id={"page_" + control.Content.MetaData.Name}
        title={data.Title}
      >
        <div className={`${css.tc_button_content} ${getFlexDirection()}`}>
          {getGlyphId() && props.showIcon !== false && (
            <K2Img glyphId={getGlyphId()} vcx={control.VCX} height={control.VCX.sizeMap(20)} width={control.VCX.sizeMap(20)} />
          )}
          {!isStaticVRTab() && parentTab.Ncl.FrgtData.TabIconPosition !== IconPosition.ipCenter && <K2TruncateText>{data.Title}</K2TruncateText>}
        </div>
        {props.onClick && (
          <div className={css.tc_button_options}>
            {renderExpandIcon() && (
              <div className={`button ${css.tc_expand_icon}`} onClick={handleExpandClick}>
                <K2Img glyphId={"wui*roofdown"} vcx={control.VCX} height={control.VCX.sizeMap(20)} width={control.VCX.sizeMap(20)} />
              </div>
            )}
            {control.Ncl.IsDynamic && data.IsPinned === false && (
              <span className="button" onClick={handleCloseClick} data-k2-test-id={`close_${control.Content.MetaData.Name}`}>
                <K2Img
                  glyphId={"wui*close"}
                  vcx={control.VCX}
                  width={control.VCX.sizeMap(20)}
                  height={control.VCX.sizeMap(20)}
                  strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorFrg1)}
                />
              </span>
            )}
            {control.Ncl.IsDynamic && data.IsPinned === true && (
              <K2Img
                glyphId={"wui*pinleftdown"}
                vcx={control.VCX}
                width={control.VCX.sizeMap(20)}
                height={control.VCX.sizeMap(20)}
                strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorFrg1)}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default K2Tab;
