import React, { useEffect, useRef, useState } from "react";
import { AcquireControl, K2ComponentState, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2Header } from "../Expander/K2Expander";
import { UpdateControl } from "../../common/communication.base";
import { UpdateHeadered, UpdateInnerGantt } from "../../common/communication.base";
import { NclGantt, NclGanttFooter, NclGanttContent, NclInnerGantt } from "../../common/components.ncl";
import { updateDataAfterDrop, NoData, initGanttData } from "../common/GanttFunctions";
import K2ToolBar from "../ToolBar/K2ToolBar";
import css from "./Gantt.scss";
import { useServerState } from "../hooks";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import Exporting from "highcharts/modules/exporting";
import draggable from "highcharts/modules/draggable-points";
import patternFill from "highcharts/modules/pattern-fill";
import boost from "highcharts/modules/boost";
import xrange from "highcharts/modules/xrange";

highchartsGantt(Highcharts);
Exporting(Highcharts);
draggable(Highcharts);
patternFill(Highcharts);
boost(Highcharts);
xrange(Highcharts);

interface DesktopVCX {
  Fake_VCXColorMap: string;
  Fake_VCXZoom: number;
  OwnBackgroundColor: string;
  CapacitiesColor: string;
  ClientZoom: number;
}

interface FragmentDataString {
  ByOperations: boolean;
  ByResources: boolean;
  HideFooter: boolean;
  HideHeader: boolean;
  InEditMode: boolean;
  HeaderAtBottom: boolean;
  OwnColors: boolean;
  HideNavigator: boolean;
  HideXAxis: boolean;
  HideYAxis: boolean;
  HideDateIndicator: boolean;
  HideRangeSelectorDates: boolean;
  //ShowDateTime: boolean;
  TwoRowXAxis: boolean;
  TimeAxisPrecision: number;
  DraggableStartEnd: boolean;
  PointSelectAllowed: boolean;
  LeftAxisTableColumns: string[];
}

interface SelectionString {
  //Type: number;
  UnselectedList: any; //List<string>;
  SelectedList: any; //List<string>;
}

interface ExportToImageString {}

interface GOptions extends Highcharts.Options {
  xAxis?: GXAxisOptions | Array<GXAxisOptions>;
}

interface GXAxisOptions extends Highcharts.XAxisOptions {
  dateTimeLabelFormats?: GAxisDateTimeLabelFormatsOptions;
}

interface GAxisDateTimeLabelFormatsOptions extends Highcharts.AxisDateTimeLabelFormatsOptions {
  hour?: GAxisDateTimeLabelFormatsOptionsObject;
  day?: GAxisDateTimeLabelFormatsOptionsObject;
  week?: GAxisDateTimeLabelFormatsOptionsObject;
  month?: GAxisDateTimeLabelFormatsOptionsObject;
}

interface GAxisDateTimeLabelFormatsOptionsObject extends Highcharts.AxisDateTimeLabelFormatsOptionsObject {
  list?: string[];
}

export const K2InnerGantt = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclInnerGantt, UpdateInnerGantt, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclInnerGantt
  );
  (window as any).TNclInnerGantt = {
    ...(window as any).TNclInnerGantt,
    updateFragmentDataString,
    updateVCX,
    updateSelection,
    updateAllCapacities,
    exportToImageFunction,
    updatePointHoverFunction,
  };
  const [chartOptions, setChartOptions] = useState<GOptions>();
  const [version, setVersion] = useState("");
  const ganttRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement> }>();
  const clickTime = useRef(-1);
  const clickPoint = useRef("");
  const clickDetected = useRef(false);
  const colors = control.Colors;
  const zoom = useRef(1);
  const disableWheel = useRef(false);
  const userRangeSelect = useRef({ x1: undefined, x2: undefined, y1: undefined, y2: undefined });
  const userRangeSelectTimer = useRef(0);
  const pointHoverTimer = useRef(0);
  const initNewGantt = useRef(true);
  const prevGanttId = useRef(undefined);

  function updateFragmentDataString(data: FragmentDataString) {
    control.Container = new NclGantt({ FrgtData: { ...data } } as any, parent as any, null, null);
  }

  function updateVCX(state: DesktopVCX) {
    control.Colors = state.Fake_VCXColorMap.split(";");
    control.VCXZoom = state.Fake_VCXZoom / 100;
    control.OwnBackgroundColor = state.OwnBackgroundColor;
    control.CapacitiesColor = state.CapacitiesColor;
    control.ClientZoom = state.ClientZoom;
  }

  //const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  function updateAllCapacities(AData: SelectionString /*AllCapacitiesString*/) {
    //ganttRef?.current?.chart.showLoading("ahoj");

    //const utcDate1 = new Date();
    //alert(utcDate1.toLocaleString());

    //let capacities = getCapacities(data.Capacities.toJS());

    let lCapacitySerie: Highcharts.Series;
    lCapacitySerie = ganttRef?.current?.chart.get("capacities") as Highcharts.Series;
    //CapacitySerie.points.

    //let seriesXrangeOptions: Highcharts.SeriesXrangeOptions = {
    //  name: "Kapacity",
    //  id: "capacities",
    //  type: "xrange",
    //  data: [],
    //};
    //lCapacitySerie?.update(seriesXrangeOptions, false);

    const capacitiesArray = new Array<Highcharts.XrangePointOptionsObject>();
    lCapacitySerie?.setData(capacitiesArray, false, false, false);

    //lCapacitySerie?.remove(false, false, false);

    //sleep(10000);

    //setVersion(data.GanttUniqueIdentifier + new Date().getTime());
    ganttRef?.current?.chart.redraw();

    //ganttRef?.current?.chart.hideLoading();
  }

  function updateSelection(AData: SelectionString) {
    //const utcDate1 = new Date();
    //alert(utcDate1.toLocaleString());

    //data.Type;

    //ganttRef?.current?.chart.showLoading("ahoj");

    for (const unselectedId of AData.UnselectedList) {
      let lPoint: Highcharts.Point;
      lPoint = ganttRef?.current?.chart.get(unselectedId.toString()) as Highcharts.Point;
      if (lPoint?.selected) {
        //alert("unselect:" + lPoint.options.id.toString());
        //lPoint.select(false, true); //lPoint?.update neprekresli ostatni unselectovane usecky
        //lPoint.update({ selected: false }, true, false);
        lPoint.selected = false;
        lPoint.setState("normal");
      }
    }

    for (const selectedId of AData.SelectedList) {
      let lPoint: Highcharts.Point;
      lPoint = ganttRef?.current?.chart.get(selectedId.toString()) as Highcharts.Point;
      //lPoint.selected = true;
      if (!lPoint?.selected) {
        //alert("select:" + lPoint.options.id.toString());
        //lPoint.select(true, true); //neudela redraw
        //lPoint.update({ selected: true }, true, false);
        lPoint.selected = true;
        lPoint.setState("select");
      }
    }

    //setVersion(data.GanttUniqueIdentifier + new Date().getTime());
    //ganttRef?.current?.chart.redraw();

    //ganttRef?.current?.chart.hideLoading();
  }

  function exportToImageFunction(state: ExportToImageString) {
    let exportingOptions: Highcharts.ExportingOptions;
    let chartOptions: Highcharts.Options;
    let svgString: string;

    //exportingOptions.type = "application/pdf";
    //exportingOptions.filename = 'my-pdf';

    //this.ganttApi.chart.exportChart(exportingOptions, chartOptions);

    //chartOptions = this.ganttApi.chart.options;

    //this.ganttApi.chart.print();

    //alert('ahoj');

    svgString = ganttRef.current.chart.getSVG(chartOptions);

    (window as any).k2handler.CallAppendFunction("ImageSvgString", svgString);
  }

  function updatePointHoverFunction(state: { Hint: string }) {
    const tooltip = ganttRef.current?.chart.tooltip as any;

    tooltip?.label?.attr({ text: state.Hint }); // undocumented API; https://github.com/highcharts/highcharts/issues/6824#issuecomment-307730886

    // posun tooltipu v pripade presahu mimo okno
    if (tooltip?.label?.width + tooltip?.label?.x > window.innerWidth) {
      tooltip?.label?.attr({ x: window.innerWidth - tooltip?.label?.width - 20 });
    }
  }

  useEffect(() => {
    control.setMaxRowCount(1);

    Highcharts.setOptions({
      lang: {
        months: ["Leden", "Únor", "Březen", "Duben", "Květěn", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
        shortMonths: ["Led", "Únor", "Bře", "Dub", "Kvě", "Čer", "Čec", "Srp", "Zář", "Říj", "Lis", "Pro"],
        weekdays: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
        shortWeekdays: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
        rangeSelectorFrom: "Od",
        rangeSelectorTo: "Do",
        downloadCSV: "Stáhnout CSV",
        downloadJPEG: "Stáhnout JPEG",
        downloadPDF: "Stáhnout PDF",
        downloadPNG: "Stáhnout PNG",
        downloadSVG: "Stáhnout SVG",
        downloadXLS: "Stáhnout XLS",
        viewFullscreen: "Zobrazit na celou obrazovku",
        exitFullscreen: "Opustit celou obrazovku",
        printChart: "Vytisknout",
        loading: "Načítání dat",
      },
    });
  }, []);

  function initGanttOptions() {
    const ganttOptions: GOptions = {
      boost: {
        enabled: true,
        allowForce: true,
        useGPUTranslations: true,
        seriesThreshold: 0,
        //usePreallocated: true,
      },
      loading: {
        hideDuration: 1000,
        showDuration: 1000,
      },
      chart: {
        backgroundColor: getBgColor(),
        spacing: [21, 16, 20, 0], // 21 = odsazeni shora, 16 = odsazeni pro scrollbar
        style: {
          fontFamily: "inherit",
        },
        animation: false,
        zooming: { type: control.Container.byOperations() ? "x" : "xy" },
        events: {
          //click: this.props.handleGanttContextMenu,
          //click: (e) => {
          //  this.props.handleGanttContextMenu(e);
          //},
          load: handleLoad,
        },
        // height: element.current.offsetHeight * zoom.current,
      },
      xAxis: [
        {
          //dolni
          min: control.XAxisOptions.minRangeDate == 0 ? null : control.XAxisOptions.minRangeDate * 1000,
          max: control.XAxisOptions.maxRangeDate == 0 ? null : control.XAxisOptions.maxRangeDate * 1000,
          events: {
            afterSetExtremes: (e) => {
              if (!control.Container.Ncl.FrgtData.ZoomReloadData) return;

              if (e.trigger === "zoom" || e.trigger === "navigator" || e.trigger === "rangeSelectorButton") {
                userRangeSelect.current.x1 = e.userMin?.toString();
                userRangeSelect.current.x2 = e.userMax?.toString();

                clearTimer(userRangeSelectTimer.current);

                userRangeSelectTimer.current = window.setTimeout(() => {
                  control.userRangeSelect(userRangeSelect.current);

                  userRangeSelect.current = { x1: undefined, x2: undefined, y1: undefined, y2: undefined };
                }, 300);
              }
            },
          },
          visible: !control.Container.hideXAxis(),
          opposite: !control.Container.headerAtBottom(),
          plotBands: control.XAxisOptions.plotBands,
          currentDateIndicator: control.Container.hideDateIndicator()
            ? false
            : {
                width: 2,
                dashStyle: "Solid",
                color: "red",
                label: {
                  format: "%Y-%m-%d",
                },
              },
          //tickInterval: control.Container.timeAxisPrecision(),
          //tickColor: this.props.colors.ContentFrame1,
          //minorTickInterval: control.Container.timeAxisPrecision(), //undefined, //"auto",
          minorGridLineColor: "var(--ColorMap-ContentFrame1)",
          type: "datetime",
          dateTimeLabelFormats: {
            //day: {
            //  list: [`${this.props.showDateTime ? "%d.%m." : ""} %H:%M`, "%a, %e. %b", "%d.%m.", "%d"],
            //},
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. týden %Y", "%W. t."],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          grid: {
            cellHeight: 27 * control.VCXZoom,
            borderColor: "var(--ColorMap-ContentFrame1)",
          },
          labels: {
            allowOverlap: true,
            overflow: "allow",
            padding: 5,
            style: {
              fontSize: "11px",
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
            align: "center",

            formatter: (context) => {
              return formatterLabel(context);
            },
          },
        },
        {
          //horni
          visible: !control.Container.hideXAxis() && control.Container.twoRowXAxis(),
          opposite: !control.Container.headerAtBottom(),
          plotBands: control.XAxisOptions.plotBands,
          type: "datetime",
          grid: {
            cellHeight: 27 * control.VCXZoom,
            borderColor: "var(--ColorMap-ContentFrame1)",
          },
          dateTimeLabelFormats: {
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. týden %Y"],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          labels: {
            allowOverlap: true,
            overflow: "allow",
            style: {
              fontSize: "11px",
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
            align: "center",
            formatter: (context) => {
              return formatterLabel(context);
            },
          },
        },
      ],
      navigator: {
        enabled: !control.Container.hideNavigator(),
        adaptToUpdatedData: false,
        series: {
          visible: false,
          //data: [],
          type: "gantt",
          pointPadding: 0.25,
          pointWidth: null,
        },
        yAxis: {
          reversed: true,
          categories: [],
        },
        xAxis: {
          gridLineColor: "var(--ColorMap-ContentFrame1)",
          labels: {
            style: {
              color: "var(--ColorMap-DataBrowseColorFrg)",
            },
          },
        },
        outlineColor: "var(--ColorMap-ContentFrame1)",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          boostThreshold: 1, // Boost when there are more than 1 point in the series.
          cropThreshold: 1,
          animation: false,
          turboThreshold: 0,
          allowPointSelect: true,
          enableMouseTracking: true,
          point: {
            events: {
              mouseOver: function (e) {
                clearTimer(pointHoverTimer.current);

                pointHoverTimer.current = window.setTimeout(() => {
                  const target: any = e.target;

                  control.pointHover(target.id);
                }, 300);
              },
              mouseOut: function () {
                clearTimer(pointHoverTimer.current);
              },
            },
          },
        },
        xrange: {
          //tooltip: {
          //  enabled: false,
          //},
          //colorByPoint: true,
          //color: control.CapacitiesColor,
          //colors: [control.CapacitiesColor ? control.CapacitiesColor : "lightgray"],
          allowPointSelect: false, //control.Container.pointSelectAllowed(),
          enableMouseTracking: false, //control.Container.pointSelectAllowed(),
          showInNavigator: false,
          skipKeyboardNavigation: true,
          zIndex: 5,
          pointPadding: 0,
          turboThreshold: 0,
          animation: false,
          borderRadius: 0,
          pointWidth: 15 * control.VCXZoom,
          //dragDrop: {
          //  draggableX: false,
          //  draggableY: false,
          //},
          states: {
            hover: {
              animation: false,
            },
            inactive: {
              enabled: false,
              animation: false,
            },
            normal: {
              animation: false,
            },
            select: {
              animation: false,
              enabled: true,
            },
          },
          point: {
            events: {
              click: handleCapacityClick,
            },
          },
        },
        gantt: {
          //tooltip: { enabled: !control.HideHint },
          //grouping: true, nechci
          turboThreshold: 0,
          animation: false,
          zIndex: 10,
          borderRadius: 0,
          borderColor: "var(--ColorMap-DataBrowseColorFrg)", //tohle dát asi na parametr
          borderWidth: 1,
          pointWidth: null,
          dataLabels: {
            format: "{point.description}",
          },
          allowPointSelect: control.Container.pointSelectAllowed(),
          //enableMouseTracking: control.Container.pointSelectAllowed(),
          dragDrop: {
            dragMinY: control.Container.byResources() ? 0 : 0,
            dragMaxY: control.Container.byResources() ? control.YAxisOptions.categories.length - 1 : 0,
            draggableStart: control.Container.draggableStartEnd() && control.InEditMode,
            draggableEnd: control.Container.draggableStartEnd() && control.InEditMode,
            dragPrecisionX: control.Container.timeAxisPrecision(), //1000 * 60 * 60 * 24,
            draggableX: control.InEditMode && control.DraggableX,
            draggableY: control.InEditMode && control.DraggableY,
            groupBy: "groupById",
          },
          states: {
            hover: {
              animation: !control.Container.byResources(),
            },
            inactive: {
              enabled: !control.Container.byResources(),
            },
            normal: {
              animation: !control.Container.byResources(),
            },
            select: {
              animation: false,
              enabled: true,
              borderColor: "red",
              borderWidth: 2,
              //color: point.color,
            },
          },
          point: {
            events: {
              //select: this.props.handleSelect,
              //unselect: this.props.handleUnselect,
              click: handleClick,
              drop: handleDrop,
            },
          },
        },
      },
      tooltip: {
        animation: false,
        hideDelay: 0,
        enabled: !control.HideHint,
        formatter: () => "...",
      },
      scrollbar: {
        enabled: control.Container.hideRangeSelectorDates(),
      },
      rangeSelector: {
        inputEnabled: !control.Container.hideRangeSelectorDates(),
        //inputBoxHeight: 0,
        //inputBoxWidth: 0,
        enabled: !control.Container.hideNavigator(),
        verticalAlign: "bottom",
        buttonPosition: { align: "right" },
        dropdown: "never",
        buttons: [
          {
            type: "all",
            text: "reset",
          },
        ],
      },
      exporting: {
        enabled: false,
      },
      series: control.SeriesDataItems,
      yAxis: {
        ...control.YAxisOptions,
        visible: !control.Container.hideYAxis(),
        gridLineColor: "var(--ColorMap-ContentFrame1)",
        grid: {
          ...control.YAxisOptions.grid,
          borderColor: "var(--ColorMap-ContentFrame1)",
        },
        events: {
          afterSetExtremes: function (e) {
            if (!ganttRef.current) return;

            if (e.trigger === "zoom" && zoom.current !== 1) {
              disableWheel.current = true;
            } else {
              disableWheel.current = false;
            }

            if (e.trigger === "zoom" || e.trigger === "navigator") {
              userRangeSelect.current.y1 = e.userMin?.toString();
              userRangeSelect.current.y2 = e.userMax?.toString();
            }

            initGanttData(data, control, colors, zoom.current);
            setChartOptions({ series: control.SeriesDataItems });
          },
        },
      },
    };

    return ganttOptions;
  }

  useEffect(() => {
    if (data.Operations.size === 0 && data.Resources.size === 0) return;

    if (prevGanttId.current != null && prevGanttId.current !== data.GanttUniqueIdentifier) {
      initNewGantt.current = true;
    }

    prevGanttId.current = data.GanttUniqueIdentifier;
    zoom.current = control.ClientZoom;

    initGanttData(data, control, colors, zoom.current);

    if (initNewGantt.current) {
      // pri prechodu na jiny graf, znovu nainicializovat komponentu, aby se spravne vykreslila vyska osy Y
      setChartOptions(initGanttOptions());
      setVersion(data.GanttUniqueIdentifier);
    } else {
      // pokud prijde update na stejny graf, neupdatovat osu Y, protoze se osa spatne prekresli
      const options = initGanttOptions();
      delete options.yAxis;
      setChartOptions(options);
    }

    initNewGantt.current = false;
  }, [data, control.InEditMode]);

  function clearTimer(timer: number) {
    if (timer) {
      window.clearTimeout(timer);
    }
  }

  const getBgColor = () => {
    return control.OwnBackgroundColor
      ? control.OwnBackgroundColor
      : !control.InEditMode
      ? "var(--ColorMap-DataBrowseColorBck)"
      : "var(--ColorMap-DataChangeColorBck)";
  };

  function formatterLabel(context: Highcharts.AxisLabelsFormatterContextObject) {
    if (context.dateTimeLabelFormat) {
      const pos: number = context.pos; // as number;
      const value: string = Highcharts.dateFormat(context.dateTimeLabelFormat, pos);
      const dayNum: number = new Date(pos).getDay();
      if (dayNum == 6 || dayNum == 0) {
        return `<span style="color: red">${value}</span>`;
      } else {
        return value;
      }
    } else return "";
  }

  const handleClick = (e: Highcharts.PointClickEventObject) => {
    handleClickInternal(e, 0, (e.point.series.options as any).allowPointSelect /* !control.Container.pointSelectAllowed()*/); //intervaly
    return false; //vypinam implicitni obsluhu onClick (oznaceni usecky)
  };

  const handleCapacityClick = (e: Highcharts.PointClickEventObject) => {
    handleClickInternal(e, 1, false); //kapacity
    return false;
  };

  const handleClickInternal = (e: Highcharts.PointClickEventObject, t: number, pointSelectAllowed: boolean) => {
    if (e.point?.options?.id === undefined) return;

    if (e.point.selected && !pointSelectAllowed && !e.ctrlKey) {
      control.defaultAccept([e.point.options.id.toString(), t]);
    }

    let dlbclick: boolean;
    const now: number = Date.now();
    const diff: number = now - clickTime.current;

    if (clickDetected.current && diff < 400 && e.point.options.id == clickPoint.current) {
      dlbclick = true;
      clickDetected.current = false;
      clickTime.current = 0;
    } else {
      dlbclick = false;
      clickDetected.current = true;
      clickTime.current = Date.now();
    }

    clickPoint.current = e.point.options.id;

    if (pointSelectAllowed) {
      if (e.shiftKey) {
        control.defaultAccept([e.point.options.id.toString(), t]);
        return false;
      } else {
        if (!e.point.selected) {
          control.selectPoint([e.point.options.id.toString(), t, e.ctrlKey ? "1" : "0"]);
        } else {
          control.unselectPoint([e.point.options.id.toString(), t, e.ctrlKey ? "1" : "0"]);
        }
        //ve Vyrobnim planovaci je zakernost ze se pomoci K2 selectuji/unselectuji zaroven s touto i jine usecky
      }
    }

    //not pointSelectAllowed
    else if (/*(e.point.options.id != this.clickPoint)*/ !dlbclick) {
      control.ganttGetRecord([e.point.options.id.toString(), t]);
    } else {
      control.defaultAccept([e.point.options.id.toString(), t]);
    }
  };

  const handleGanttContextMenu = (e: Highcharts.PointerEventObject) => {
    control.ganttContextMenu();
  };

  function handleSelect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    control.selectPoint([point.options.id, e.accumulate]);
  }

  function handleUnselect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    control.unselectPoint([point.options.id, e.accumulate]);
  }

  const handleRangeSelect = (e: Highcharts.AxisSetExtremesEventObject) => {
    control.rangeSelect([e.min.toString(), e.max.toString()]);
  };

  const handleDrop = (e: Highcharts.PointDropEventObject) => {
    const updatedData = updateDataAfterDrop(e);
    control.change(["UpdatedData", updatedData.length.toString(), updatedData.toString(), "TargetID", e.target.options.id.toString()]);
  };

  const handleWheel = (e: React.WheelEvent) => {
    if (!e.shiftKey || disableWheel.current) return;

    (ganttRef.current.chart.yAxis[0] as any).staticScale = 0;
    zoom.current = zoom.current - (e.deltaY * zoom.current) / 1000;

    initGanttData(data, control, colors, zoom.current);
    setChartOptions(initGanttOptions());
    setVersion(data.GanttUniqueIdentifier + Date.now()); // gantt neumi dynamicky menit vysku radku (staticScale), takze kompletne znovu inicializuji komponentu
    control.setClientZoom([zoom.current.toString()]);
  };

  function handleLoad(e: Event) {
    const chart = this as unknown as Highcharts.Chart;
  }

  if (control.Container.Ncl.FrgtData.ByOperations == null) return;

  let content;

  if (!colors || chartOptions == null) {
    if (
      (control.Container.Ncl.FrgtData.ByOperations && control.SeriesDataItems.length === 0) ||
      (control.Container.Ncl.FrgtData.ByResources && control.Categories.length === 0)
    ) {
      content = NoData(colors);
    }
  } else {
    content = <HighchartsReact key={version} ref={ganttRef} highcharts={Highcharts} options={chartOptions} constructorType={"ganttChart"} />;
  }

  return (
    <div
      ref={element}
      style={StyleHelper(control, {
        ...props.style,
        flex: "1 1 auto",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      })}
      className={css.gantt}
    >
      <div
        className="gantt-wrapper"
        style={{
          height: "100%",
          width: "100%",
          display: "block",
          backgroundColor: getBgColor(),
        }}
        onWheel={handleWheel}
      >
        {content}
      </div>
      {control.Container.showFooter() && <K2GanttFooter controlUID={control.Container.Footer.MetaData.ControlUID} vrUID={control.getRealizerUID()} />}
    </div>
  );
};

export class K2GanttContent extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttContent`;
  private control: NclGanttContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttContent;
    }) as NclGanttContent;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    if (this.control.GanttInner?.MetaData?.ControlUID === undefined) return;
    return (
      <div style={StyleHelper(this.control, { flex: "1 1 auto", ...this.props.style })}>
        <K2InnerGantt controlUID={this.control.GanttInner.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2GanttFooter extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttFooter`;
  private control: NclGanttFooter;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttFooter;
    }) as NclGanttFooter;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        <K2ToolBar controlUID={this.control.LeftToolbar.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2Gantt extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateHeadered>> {
  private control: NclGantt;
  static displayName = `K2Gantt`;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGantt;
    }) as NclGantt;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateHeadered>) => {
      return { data: state as UpdateHeadered };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let title = this.state.data.Title ? this.state.data.Title : "";
    if (this.state.data.TitleSuffix) {
      title += " " + this.state.data.TitleSuffix;
    }

    return (
      <div style={StyleHelper(this.control, { ...this.props.style, flexDirection: "column" })}>
        {this.control.isShowHeader() && <K2Header controlUID={this.control.Header.MetaData.ControlUID} vrUID={this.props.vrUID} title={title} />}
        <K2GanttContent controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.props.vrUID} />
      </div>
    );
  }
}

//DEMO: https://www.highcharts.com/gantt/demo/project-management/dark-unica
//MANUAL: https://www.highcharts.com/docs/gantt/gantt-task-dependencies
//API: https://api.highcharts.com/gantt/
//FORUM: https://www.highcharts.com/forum/
//Organizacni struktura: https://www.highcharts.com/blog/tutorials/how-to-use-an-org-chart-more-effectively/
