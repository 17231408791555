import React, { useCallback, useRef } from "react";
import { EdgeProps, EdgeText, getBezierPath, useStore } from "reactflow";
import { EdgeState } from "../../common/communication.base";
import { getEdgeParams } from "./utils";

export default function CustomEdge(props: EdgeProps) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(props.source), [props.source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(props.target), [props.target]));
  const lineElement = useRef<SVGPathElement>(null);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const path = `M ${sx},${sy} L ${tx},${ty},`;

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  let classes = "";
  let marker = props.markerEnd?.toString();
  if (props.data !== undefined) {
    switch (props.data?.state) {
      case EdgeState.backLink:
        classes = "backLink";
        marker = "url(#arrowBack)";
        break;
      case EdgeState.activeLink:
        classes = "activeLink";
        marker = "url(#arrowActive)";
        break;
      default:
        classes = "defaultLink";
        break;
    }
  }

  if (sourceNode.type === "Step" && props.data?.state === EdgeState.activeLink) {
    classes += " sourceAnchor";
  }
  if (targetNode.type === "Step" && props.data?.state === EdgeState.activeLink) {
    classes += " targetAnchor";
  }

  // Přepis pozice později renderovaných Anchoru.
  if (lineElement.current) {
    const anchorList = lineElement.current.parentElement?.querySelectorAll(".react-flow__edgeupdater");
    const source = anchorList[0];
    const target = anchorList[1];
    if (source) {
      source.classList.add("react-flow__edgeupdater-source");
      source.setAttribute("cx", String(sx));
      source.setAttribute("cy", String(sy));
    }
    if (target) {
      target.classList.add("react-flow__edgeupdater-target");
      target.setAttribute("cx", String(tx));
      target.setAttribute("cy", String(ty));
    }
  }

  return (
    <>
      <path ref={lineElement} id={props.id} style={props.style} className={"react-flow__edge-path " + classes} d={path} markerEnd={marker}></path>
      <EdgeText x={edgeCenterX} y={edgeCenterY} label={props.label} />
    </>
  );
}
