import React from "react";
import { getAttributes } from "../../common/common";
import { DisplayMode, UpdateCheckBox } from "../../common/communication.base";
import { NclCheckBox } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2Text from "../Text/K2Text";
import css from "./CheckBox.scss";

const K2CheckBox = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclCheckBox, UpdateCheckBox, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclCheckBox
  );

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    control.contextMenu();
  };

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    e.stopPropagation();
    control.setActiveControlRequested();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (data.ReadOnly) return;

    switch (e.key) {
      case " ":
        e.stopPropagation();
        control.check();
        break;
      case "Enter":
      case "ArrowDown":
        e.stopPropagation();
        control.resolveFocus(true);
        break;
      case "ArrowUp":
        e.stopPropagation();
        control.resolveFocus(false);
        break;
    }
  };

  const clickEvent = () => {
    if (data.ReadOnly) return;

    control.check();
  };

  const getCheckBoxClass = () => {
    let className = `${css.cb_input}`;

    if (control.InEditMode) {
      className += ` ${css.cb_input_edit}`;
    }
    if (data.Checked) {
      className += " cb_input_checked";
    }

    return className;
  };

  return (
    <div
      ref={element}
      style={StyleHelper(control, props.style)}
      data-k2-test-id={control.Ncl.Name}
      className={`${css.cb} ${css.cb_font}`}
      {...getAttributes(data)}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onContextMenu={handleContextMenu}
      onClick={clickEvent}
    >
      <div className={getCheckBoxClass()}>
        {data.Checked && (
          <K2Img
            glyphId="wui*checkbox"
            vcx={control.VCX}
            strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.ContentDecorateColorFrg)}
            stretch={true}
          />
        )}
      </div>
      <K2Text vcx={control.VCX} displayMode={DisplayMode.fpdmDecorated} className={css.cb_label}>
        {data.Title}
      </K2Text>
    </div>
  );
};

export default K2CheckBox;
