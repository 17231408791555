import React, { useEffect, useMemo, useRef, useState } from "react";
import { base64ToBlob, CancelablePromise, isPDF, isUrl, Log, makeCancelable } from "../../common/common";
import { MediaData, MediaManager } from "../../common/mediaManager";
import K2TruncateText from "../Text/K2TruncateText";

interface FileViewerProps {
  url: string;
}

const urlPostfix = "?page=1&pagemode=none&toolbar=0&statusbar=0&navpanes=0";

const K2FileViewer = (props: FileViewerProps) => {
  const promise = useRef<CancelablePromise<MediaData>>();
  const [data, setData] = useState("");
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const viewerEnabled = !isPDF(props.url) || navigator.pdfViewerEnabled;

  useEffect(() => {
    initData();
  }, []);

  const blobUrl = useMemo(() => {
    if (data === "") return;

    const base64String = data.split(",")[1];
    const blob = base64ToBlob(base64String, "application/pdf");
    const url = URL.createObjectURL(blob);

    return url;
  }, [data]);

  const initData = () => {
    if (isUrl(props.url)) {
      setData(props.url);
      setUrl(props.url + (isPDF(props.url) ? urlPostfix : ""));
    } else {
      promise.current = makeCancelable<MediaData>(MediaManager.tryGetData(props.url));

      promise.current.promise
        .then((data) => {
          if (data.errorMessage) {
            setErrorMessage(data.errorMessage);
            setUrl(props.url);
          } else {
            setData(data.base64Data);
            setUrl(props.url);
          }
        })
        .catch((reason) => {
          Log.error("error: " + reason, null);
          setErrorMessage("Soubor se nepodařilo stáhnout.");
        });
    }
  };

  function handleDownloadClick() {
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = url;
    downloadLink.click();
  }

  if (!viewerEnabled) {
    return (
      <div className="notSupportedFile">
        <button className="loginBtn" onClick={handleDownloadClick}>
          Otevřít
        </button>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <K2TruncateText line={10} style={{ alignSelf: "center" }}>
        {errorMessage}
      </K2TruncateText>
    );
  }

  return <object data={isPDF(props.url) ? blobUrl : data} className="file_object" />;
};

export default K2FileViewer;
