import React, { useEffect, useState } from "react";
import { BaseWidgetProps } from "../ClientWidget";
import "./ClockWidget.scss";

export default function K2ClockWidget(props: BaseWidgetProps) {
  const [dateTime, setDateTime] = useState(new Date());
  const dayOfWeek = ["po", "út", "st", "čt", "pá", "so", "ne"];
  let styleType = "0";

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function getDate() {
    return dayOfWeek[dateTime.getDay()] + " " + dateTime.getDate() + "." + (dateTime.getMonth() + 1) + "." + dateTime.getFullYear();
  }
  function getHours() {
    return dateTime.getHours() >= 10 ? dateTime.getHours() : "0" + dateTime.getHours();
  }
  function getMinutes() {
    return dateTime.getMinutes() >= 10 ? dateTime.getMinutes() : "0" + dateTime.getMinutes();
  }
  function getSeconds() {
    return dateTime.getSeconds() >= 10 ? dateTime.getSeconds() : "0" + dateTime.getSeconds();
  }
  if (props.AdditionalField === "1") {
    styleType = "1";
  }

  return (
    <div className={`cw_clock style_${styleType}`}>
      <div className="cw_clock_time">
        <div className="cw_clock_hours" title="Hodiny">
          <span>{getHours()}</span>
        </div>
        <span className="cw_separator">:</span>
        <div className="cw_clock_minutes" title="Minuty">
          <span>{getMinutes()}</span>
        </div>
        <span className="cw_separator">:</span>
        <div className="cw_clock_seconds" title="Sekundy">
          <span>{getSeconds()}</span>
        </div>
      </div>
      <div className="cw_clock_date" title="Datum">
        <span>{getDate()}</span>
      </div>
    </div>
  );
}
